export const getStorage = (k) => {
  return localStorage.getItem(k);
};

export const setStorage = (k, v) => {
  localStorage.setItem(k, v);
};

export const clearItem = (k) => {
  localStorage.removeItem(k);
};
