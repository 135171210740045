import {
  FETCH_REPORTS_OPTIONS,
  IS_FETCHING_REPORTS,
  FETCH_REPORTS_HISTORY,
  CLEAR_REPORTS_HISTORY,
} from "Redux/Actions/types";
import reportReducerUtils from "utils/reducerUtils/reportReducerUtils";

const initialState = {
  options: [],
  isFetching: false,
  history: [],
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_OPTIONS:
      return {
        ...state,
        options: action.options,
        isFetching: false,
      };
    case IS_FETCHING_REPORTS:
      return { ...state, isFetching: action.isFetching };
    case FETCH_REPORTS_HISTORY:
      return reportReducerUtils.fetchReportHistory(state, action);
    case CLEAR_REPORTS_HISTORY:
      return { ...state, history: [] };
      I;
    default:
      return state;
  }
};

export default reportReducer;
