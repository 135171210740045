import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthRoute, UnAuthRoute } from "utils/routes";
import Loading from "pages/loading";
import AlertBar from "Components/Alert";

const ViewMain = React.lazy(() => import("./pages"));
const ViewApp = React.lazy(() => import("./pages/app"));
const ViewUser = React.lazy(() => import("./pages/auth"));
const ViewError = React.lazy(() => import("./pages/error"));

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const brand = process.env.REACT_APP_BRAND;

  useEffect(() => {
    const favIconUpdate = async () => {
      const favicon = document.getElementById("favicon");
      if (brand === "Dhruv") {
        favicon.href = "/favicon.ico";
        document.title = "Dhruv Survey";
      } else {
        favicon.href = "https://gilp.nationwithnamo.com/assets/images/logo.png";
        document.title = "NwN Survey";
      }
    };
    favIconUpdate();
  }, [brand]);

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <AuthRoute path="/app" isLoggedIn={isLoggedIn} Component={ViewApp} />
          <UnAuthRoute
            path="/user"
            isLoggedIn={isLoggedIn}
            Component={ViewUser}
          />
          <Route path="/error" exact render={() => <ViewError />} />
          <Route path="/" exact render={() => <ViewMain />} />
          <Redirect to="/error" />
        </Switch>
      </Router>
      <AlertBar />
    </Suspense>
  );
};

export default App;
