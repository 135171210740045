import {
  FETCH_COLLECTOR_LIST,
  FETCH_INDIVISUAL_RES_LIST,
  FETCH_QUESTION_LIST,
  IS_FETCHING_COLLECTOR,
  IS_FETCHING_IR,
  IS_FETCHING_QUESTION,
} from "Redux/Actions/types";

const initialState = {
  individualResponseList: [],
  questionList: [],
  collectorList: [],
  isFetching: false,
  isQuestionFetching: false,
  isCollectorFetching: false,
  totalPages: 0,
};

const individualRespReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_IR:
      return { ...state, isFetching: action.payload };
    case FETCH_INDIVISUAL_RES_LIST:
      return {
        ...state,
        individualResponseList: action.payload.results,
        totalPages: action.payload.total_pages,
      };
    case FETCH_COLLECTOR_LIST:
      return { ...state, collectorList: action.payload.collector_ids };
    case IS_FETCHING_COLLECTOR:
      return { ...state, isCollectorFetching: action.payload };
    case FETCH_QUESTION_LIST:
      return { ...state, questionList: action.payload };
    case IS_FETCHING_QUESTION:
      return { ...state, isQuestionFetching: action.payload };
    default:
      return state;
  }
};

export default individualRespReducer;
