import { setStorage } from "services/storage";

function addLogic(state, action) {
  const { pId, qId, logicObj } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          let oldObj;
          if (logicObj.type === "skip") {
            oldObj = question.logics.find(
              (logic) =>
                logic.source_option_index === logicObj.source_option_index
            );
          } else if (logicObj.type === "display") {
            oldObj = question.logics.find((logic) => logic.type === "display");
          } else if (logicObj.type === "carry-forward") {
            oldObj = question.logics.find(
              (logic) => logic.type === "carry-forward"
            );
          } else {
            oldObj = question.logics.find(
              (logic) => logic.type === "carry-option"
            );
          }

          if (oldObj) {
            question.logics.splice(question.logics.indexOf(oldObj), 1);
          }
          return {
            ...question,
            logics: [...question.logics, { ...logicObj }],
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: [...updatedPages] };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function removeLogic(state, action) {
  const { pId, qId, sourceOptId, type } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          let oldObj;
          if (sourceOptId !== null && sourceOptId !== undefined) {
            oldObj = question.logics.find(
              (logic) => logic.source_option_index === sourceOptId
            );
          } else if (type === "carry-forward") {
            oldObj = question.logics.find(
              (logic) => logic.type === "carry-forward"
            );
          } else if (type === "carry-option") {
            oldObj = question.logics.find(
              (logic) => logic.type === "carry-option"
            );
          } else {
            oldObj = question.logics.find((logic) => logic.type === "display");
          }

          if (oldObj) {
            question.logics.splice(question.logics.indexOf(oldObj), 1);
          }
          return {
            ...question,
            logics: [...question.logics],
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: [...updatedPages] };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function makeCriteriaCorrupt(state, action) {
  const prevCriterias = state.currentSurvey.display_criterias;
  const updatedCriterias = prevCriterias.map((criteria) => {
    if (action.payload.includes(criteria.criteria_id)) {
      criteria.is_corrupted = true;
    }
    return criteria;
  });
  const updatedSurvey = {
    ...state.currentSurvey,
    display_criterias: updatedCriterias,
  };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: {
      ...updatedSurvey,
    },
  };
}

const logicReducerUtils = {
  addLogic,
  removeLogic,
  makeCriteriaCorrupt,
};

export default logicReducerUtils;
