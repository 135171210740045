import React from "react";

import { MuiThemeProvider, createTheme, withStyles } from "@material-ui/core/";

const theme = createTheme({
  palette: {
    primary: { main: "#5B41E1", dark: "#070921" },
    secondary: { main: "#249F5C", light: "#249F5C33" },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  overrides: {
    MuiButton: {
      text: {
        textTransform: "none",
        fontWeight: 400,
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
  },
});

const cssVariables = (theme) => ({
  "@global": {
    ":root": {
      "--color-primary": theme.palette.primary.main,
      "--color-secondary": theme.palette.secondary.main,
      "--text-primary": "#070921",
      "--text-secondary": "#475260",
      "--color-border-primary": "#E5E5E5",
    },
    "*,*::before,*::after": {
      margin: 0,
      padding: 0,
      "box-sizing": "border-box",
    },
    body: {
      backgroundColor: "#ffff!important",
    },
    ".MuiButton-root": {
      textTransform: "none!important",
      fontWeight: "400!important",
    },
    ".MuiTab-root": {
      textTransform: "none",
      fontSize: "16px",
      minWidth: "100px",
      opacity: ".9",
    },
    img: {
      width: "100%",
      height: "100%",
    },
    li: {
      listStyleType: "none",
    },
    a: {
      textDecoration: "none",
    },
    h1: {
      fontSize: "2.5rem!important",
    },
    h6: {
      fontSize: "1.2rem!important",
      fontWeight: "600!important",
    },
    ".MuiOutlinedInput-input": {
      padding: "0.6rem 0.7rem!important",
      outline: "none",
    },
    textArea: {
      padding: "0.6rem 0.7rem",
      width: "100%",
      borderRadius: "4px",
    },
    ".MuiTypography-body1": {
      fontSize: "1em!important",
      fontWeight: "inherit",
    },
    ".MuiInputBase-input.Mui-disabled": {
      cursor: "not-allowed",
    },
    ".MuiCircularProgress-root": {
      width: "28px!important",
      height: "28px!important",
    },
  },
});

const CssRoot = withStyles(cssVariables)(() => {
  return <React.Fragment></React.Fragment>;
});

const ThemeProvider = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssRoot />
      {props.children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
