import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { hideAlert } from "Redux/Actions/UI/alertActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertBar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.UI.alert.show);
  const msg = useSelector((state) => state.UI.alert.msg);
  const severity = useSelector((state) => state.UI.alert.severity);

  const handleClose = () => {
    dispatch(hideAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={show}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      {msg && (
        <Alert onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      )}
    </Snackbar>
  );
};

export default AlertBar;
