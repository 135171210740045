// Auth Action Types
export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "AUTH/SIGNUP_SUCCESS";
export const LOGOUT_SUCCESS = "AUTH/LOGOUT_SUCCESS";
export const IS_FETCHING = "AUTH/IS_FETCHING";
export const AUTH_ERROR = "AUTH/AUTH_ERROR";

// UI Action Types
export const SHOW_MODAL = "UI/SHOW_MODAL";
export const HIDE_MODAL = "UI/HIDE_MODAL";
export const SHOW_ALERT = "UI/SHOW_ALERT";
export const HIDE_ALERT = "UI/HIDE_ALERT";

// Survey Action Types
export const CREATE_NEW_SURVEY = "SURVEY/CREATE_NEW_SURVEY";
export const EDIT_SURVEY = "SURVEY/EDIT_SURVEY";
export const CLEAR_SURVEY = "SURVEY/CLEAR_SURVEY";
export const SAVE_SURVEY = "SURVEY/SAVE_SURVEY";
export const EDIT_AND_SAVE = "SURVEY/EDIT_AND_SAVE";
export const SAVE_SURVEY_AS_DRAFT = "SURVEY/SAVE_SURVEY_AS_DRAFT";
export const IS_SAVING = "SURVEY/IS_SAVING";
export const FETCH_SURVEY_LIST = "SURVEY/FETCH_SURVEY_LIST";
export const FETCH_SURVEY_LIST_BY_SEARCH = "SURVEY/FETCH_SURVEY_LIST_BY_SEARCH";
export const CLEAR_SURVEY_LIST = "SURVEY/CLEAR_SURVEY_LIST";

export const TOGGLE_SURVEY = "SURVEY/TOGGLE_SURVEY";
export const FETCH_SURVEY_BY_ID = "SURVEY/FETCH_SURVEY_BY_ID";
export const FETCH_ACTIVE_QUESTION_AND_OPTION_IDS =
  "SURVEY/FETCH_ACTIVE_QUESTION_AND_OPTION_IDS";
export const ADD_CRITERIA = "SURVEY/ADD_CRITERIA";
export const CLONE_SURVEY = "SURVEY/CLONE_SURVEY";
export const DOWNLOAD_RESPONSE = "SURVEY/DOWNLOAD_RESPONSE";

// Page Action Types
export const CREATE_PAGE = "SURVEY/CREATE_PAGE";
export const EDIT_PAGE = "SURVEY/EDIT_PAGE";
export const SAVE_PAGE = "SURVEY/SAVE_PAGE";
export const DELETE_PAGE = "SURVEY/DELETE_PAGE";
export const CHANGE_SELECTED_PAGE = "SURVEY/CHANGE_SELECTED_PAGE";
export const SORT_PAGE = "SURVEY/SORT_PAGE";
export const CLONE_PAGE = "SURVEY/CLONE_PAGE";

// Question Action Types
export const ADD_QUESTION = "SURVEY/ADD_QUESTION";

export const EDIT_QUESTION = "SURVEY/EDIT_QUESTION";
export const CHANGE_FILE_TYPE = "SURVEY/CHANGE_FILE_TYPE";
export const EDIT_PROPERTIES = "SURVEY/EDIT_PROPERTIES";
export const ADD_CHOICE = "SURVEY/ADD_CHOICE";
export const ADD_OPTION = "SURVEY/ADD_OPTION";
export const REMOVE_OPTION = "SURVEY/REMOVE_OPTION";
export const EDIT_QUES_TEXT = "SURVEY/EDIT_QUES_TEXT";
export const EDIT_OPTION_TEXT = "SURVEY/EDIT_OPTION_TEXT";
export const MARK_QUES_DONE = "SURVEY/MARK_QUES_DONE";
export const CHANGE_SELECTED_QUES = "SURVEY/CHANGE_SELECTED_QUES";
export const DELETE_QUESTION = "SURVEY/DELETE_QUESTION";
export const SAVE_DROPDOWN_CHOICES = "SURVEY/SAVE_DROPDOWN_CHOICES";
export const ADD_IMAGE_TO_OPTIONS = "SURVEY/ADD_IMAGE_TO_OPTIONS";
export const ADD_IMAGE_TO_MATRIX_ROW = "SURVEY/ADD_IMAGE_TO_MATRIX_ROW";
export const EDIT_OPTION_PROPERTIES = "SURVEY/EDIT_OPTION_PROPERTIES";
export const ADD_COMMENT_TO_OPTIONS = "SURVEY/ADD_COMMENT_TO_OPTIONS";
export const MAKE_OPTION_STATEMENT = "SURVEY/MAKE_OPTION_STATEMENT";
export const HIDE_OPTION = "SURVEY/HIDE_OPTION";
export const DYNAMICDROPDOWN_FROM_FILE = "SURVEY/DYNAMICDROPDOWN_FROM_FILE";
export const OBJECTIVEQUESTION_FROM_FILE = "SURVEY/OBJECTIVEQUESTION_FROM_FILE";
export const CLONE_QUESTION = "SURVEY/CLONE_QUESTION";
export const HIDEUNHIDE_QUESTION = "SURVEY/HIDEUNHIDE_QUESTION";
export const REORDER_QUESTIONS = "SURVEY/REORDER_QUESTIONS";
export const DELETE_MULTIPLE_QUESTIONS = "SURVEY/DELETE_MULTIPLE_QUESTIONS";
export const HIDE_UNHIDE_MULTIPLE_QUESTIONS =
  "SURVEY/HIDE_UNHIDE_MULTIPLE_QUESTIONS";
export const HIDE_MATRIX_ROW = "SURVEY/HIDE_MATRIX_ROW";
export const HIDE_MATRIX_COLUMN = "SURVEY/HIDE_MATRIX_COLUMN";
export const CLONE_MULTIPLE_QUESTIONS = "SURVEY/CLONE_MULTIPLE_QUESTIONS";

// Logic Action Types
export const ADD_LOGIC = "SURVEY/ADD_LOGIC";
export const REMOVE_LOGIC = "SURVEY/REMOVE_LOGIC";
export const MAKE_CRITERIA_CORRUPT = "SURVEY/MAKE_CRITERIA_CORRUPT";

// FA Action Types
export const GET_FA_LIST = "FA/GET_FA_LIST";
export const GET_ASSIGNED_FA_LIST = "FA/GET_ASSIGNED_FA_LIST";
export const ADD_FA = "FA/ADD_FA";
export const DELETE_FA = "FA/DELETE_FA";
export const EDIT_FA = "FA/EDIT_FA";
export const IS_FETCHING_FA = "FA/IS_FETCHING_FA";
export const ASSIGN_SURVEY_TO_FA = "FA/ASSIGN_SURVEY_TO_FA";
export const DEASSIGN_SURVEY_TO_FA = "FA/DEASSIGN_SURVEY_TO_FA";
export const FA_BULK_UPLOAD = "FA/FA_BULK_UPLOAD";

// Reports Summary Types

export const FETCH_SURVEY_SUMMARY = "SUMMARY/FETCH_SURVEY_SUMMARY";
export const IS_SUMMARY_FETCHING = "SUMMARY/IS_SUMMARY_FETCHING";

//Reports Types
export const FETCH_REPORTS_OPTIONS = "REPORTS/FETCH_REPORTS_OPTIONS";
export const IS_FETCHING_REPORTS = "REPORTS/IS_FETCHING_REPORTS";
export const FETCH_REPORTS_HISTORY = "REPORTS/FETCH_REPORTS_HISTROY";
export const CLEAR_REPORTS_HISTORY = "REPORTS/CLEAR_REPORTS_HISTORY";

//individual Response Types
export const FETCH_INDIVISUAL_RES_LIST = "IR/FETCH_INDIVISUAL_RESPONSE_LIST";
export const FETCH_QUESTION_LIST = "IR/FETCH_QUESION_LIST";
export const FETCH_COLLECTOR_LIST = "IR/FETCH_COLLECTOR_LIST";
export const FETCH_INDIVISUAL_FILTERED_LIST = "IR/FETCH_FILTER_LIST";
export const IS_FETCHING_QUESTION = "IR/IS_FETCHING_QUESTION";
export const IS_FETCHING_COLLECTOR = "IR/IS_FETCHING_COLLECTOR";
export const IS_FETCHING_IR = "IR/IS_FETCHING_IR";

//Question Tags Types
export const FETCH_QUESTION_TAGS = "TAGS/FETCH_QUESTION_TAGS";
export const SHOW_OUTLINE = "TAGS/SHOW_OUTLINE";

// State Parties List
export const FETCH_STATE_PARTIES_LIST = "LIST/STATE_PARTIES";
