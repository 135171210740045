import { setStorage, clearItem } from "services/storage";

function createPage(state, action) {
  const newSurvey = action.payload.survey;
  let prevSelected = parseInt(state.currentSurvey.selectedPage);
  const selectedIds = {
    selectedPage: action.payload.created_page._id,
    selectedQues: state.selectedIds.selectedQues,
  };
  const updatedSurvey = {
    ...newSurvey,
    selectedPage: (prevSelected += 1),
  };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  setStorage("selectedIds", JSON.stringify(selectedIds));
  return {
    ...state,
    currentSurvey: { ...updatedSurvey },
    isSaving: false,
    selectedIds,
  };
}

function editPage(state, action) {
  const { key, value, id } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id == id) {
      return {
        ...page,
        [key]: value,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: { ...updatedSurvey },
  };
}

function savePage(state, action) {
  setStorage("current_survey", JSON.stringify(action.payload));
  return {
    ...state,
    currentSurvey: action.payload,
    isSaving: false,
  };
}

function changeSelectedPage(state, action) {
  const selectedIds = {
    selectedPage: action.payload,
    selectedQues: null,
  };
  setStorage("selectedIds", JSON.stringify(selectedIds));
  return {
    ...state,
    selectedIds,
  };
}

function clonePage(state, action) {
  const newPage = action.payload.page;
  const updatedPages = [...state.currentSurvey.pages, newPage];
  const updatedSurvey = { ...state.currentSurvey, pages: updatedPages };
  const selectedIds = {
    selectedPage: newPage._id,
    selectedQues: null,
  };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
    selectedIds,
  };
}

function deletePage(state, action) {
  const newSurvey = action.payload.survey;
  let newSelectedPage = null;
  if (newSurvey.pages.length > 0) {
    newSelectedPage = newSurvey.pages[newSurvey.pages.length - 1]._id;
  }
  const selectedIds = {
    selectedPage: newSelectedPage,
    selectedQues: null,
  };
  setStorage("current_survey", JSON.stringify(newSurvey));
  setStorage("selectedIds", JSON.stringify(selectedIds));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
    selectedIds,
  };
}

function sortPage(state, action) {
  const prevSurvey = state.currentSurvey;
  const updatedSurvey = { ...prevSurvey, pages: action.payload };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: { ...updatedSurvey },
  };
}

const pageReducerUtils = {
  createPage,
  editPage,
  changeSelectedPage,
  deletePage,
  sortPage,
  savePage,
  clonePage,
};

export default pageReducerUtils;
