import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  IS_FETCHING,
  AUTH_ERROR,
} from "Redux/Actions/types";
import { getStorage, setStorage, clearItem } from "services/storage";

const isAuth = getStorage("user");

const initialState = {
  user: isAuth ? JSON.parse(getStorage("user")) : null,
  isLoggedIn: isAuth ? true : false,
  isFetching: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      setStorage("user", JSON.stringify(action.payload));
      return {
        user: action.payload,
        isLoggedIn: true,
        isFetching: false,
      };
    case LOGOUT_SUCCESS:
    case AUTH_ERROR:
      clearItem("user");
      return {
        user: null,
        isLoggedIn: false,
        isFetching: false,
      };
    case IS_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

export default authReducer;
