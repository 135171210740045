import {
  GET_FA_LIST,
  IS_FETCHING_FA,
  ADD_FA,
  DELETE_FA,
  EDIT_FA,
  GET_ASSIGNED_FA_LIST,
  ASSIGN_SURVEY_TO_FA,
  DEASSIGN_SURVEY_TO_FA,
  FA_BULK_UPLOAD,
} from "Redux/Actions/types";

const initialState = {
  fa_list: null,
  assigned_fas: [],
  isFetching: false,
};

const faReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FA_LIST:
      return {
        ...state,
        fa_list: action.payload,
        isFetching: false,
      };
    case GET_ASSIGNED_FA_LIST:
      return {
        ...state,
        assigned_fas: action.payload,
        isFetching: false,
      };
    case ADD_FA:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_FA:
      const updatedFAs = state.fa_list.filter(
        (fa) => !action.payload.includes(fa.id)
      );
      return {
        ...state,
        fa_list: [...updatedFAs],
        isFetching: false,
      };
    case EDIT_FA:
      return {
        ...state,
        isFetching: false,
      };
    case ASSIGN_SURVEY_TO_FA:
      return {
        ...state,
        isFetching: false,
      };

    case DEASSIGN_SURVEY_TO_FA:
      return {
        ...state,
        isFetching: false,
      };
    case FA_BULK_UPLOAD:
      return {
        ...state,
        isFetching: false,
      };
    case IS_FETCHING_FA:
      return {
        ...state,
        isFetching: action.payload,
      };
    default:
      return state;
  }
};

export default faReducer;
