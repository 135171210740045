function fetchReportHistory(state, action) {
  const updatedHistoryList = [
    ...new Set([...state.history, ...action.history]),
  ];
  return {
    ...state,
    history: updatedHistoryList,
    isFetching: false,
  };
}

const reportReducerUtils = {
  fetchReportHistory,
};
export default reportReducerUtils;
