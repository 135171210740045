import {
  CREATE_NEW_SURVEY,
  EDIT_SURVEY,
  EDIT_AND_SAVE,
  CLEAR_SURVEY,
  CREATE_PAGE,
  EDIT_PAGE,
  ADD_QUESTION,
  EDIT_PROPERTIES,
  ADD_OPTION,
  REMOVE_OPTION,
  EDIT_QUES_TEXT,
  EDIT_OPTION_TEXT,
  MARK_QUES_DONE,
  DELETE_QUESTION,
  EDIT_QUESTION,
  CHANGE_SELECTED_PAGE,
  DELETE_PAGE,
  IS_SAVING,
  SAVE_SURVEY,
  FETCH_SURVEY_LIST,
  TOGGLE_SURVEY,
  FETCH_SURVEY_BY_ID,
  SAVE_DROPDOWN_CHOICES,
  ADD_IMAGE_TO_OPTIONS,
  ADD_LOGIC,
  REMOVE_LOGIC,
  ADD_CRITERIA,
  MAKE_CRITERIA_CORRUPT,
  CLONE_SURVEY,
  SORT_PAGE,
  DOWNLOAD_RESPONSE,
  CHANGE_FILE_TYPE,
  DYNAMICDROPDOWN_FROM_FILE,
  OBJECTIVEQUESTION_FROM_FILE,
  CLONE_QUESTION,
  HIDEUNHIDE_QUESTION,
  SAVE_PAGE,
  REORDER_QUESTIONS,
  FETCH_SURVEY_LIST_BY_SEARCH,
  CLEAR_SURVEY_LIST,
  ADD_CHOICE,
  EDIT_OPTION_PROPERTIES,
  ADD_IMAGE_TO_MATRIX_ROW,
  FETCH_ACTIVE_QUESTION_AND_OPTION_IDS,
  FETCH_QUESTION_TAGS,
  SHOW_OUTLINE,
  CLONE_PAGE,
  DELETE_MULTIPLE_QUESTIONS,
  HIDE_UNHIDE_MULTIPLE_QUESTIONS,
  CLONE_MULTIPLE_QUESTIONS,
  FETCH_STATE_PARTIES_LIST,
  HIDE_MATRIX_ROW,
  HIDE_MATRIX_COLUMN,
} from "Redux/Actions/types";
import { getStorage } from "services/storage";

import surveyReducerUtils from "utils/reducerUtils/surveyReducerUtils";
import pageReducerUtils from "utils/reducerUtils/pageReducerUtils";
import questionReducerUtils from "utils/reducerUtils/questionReducerUtils";
import logicReducerUtils from "utils/reducerUtils/logicReducerUtils";

const hasCurrentSurvey = getStorage("current_survey");
const hasSelectedIds = getStorage("selectedIds");

const initialState = {
  currentSurvey: hasCurrentSurvey
    ? { ...JSON.parse(hasCurrentSurvey) }
    : {
        title: "",
        desc: "",
        pages: [],
        selectedPage: -1,
        selectedQues: -1,
        display_criterias: [],
        selectedEditQues: null,
        redirect_status: false,
        redirect_url: "",
        redirection_logic: {
          questions: [],
          logic: null,
        },
      },
  isSaving: false,
  selectedIds: hasSelectedIds
    ? { ...JSON.parse(hasSelectedIds) }
    : { selectedPage: null, selectedQues: null },
  survey_list: [],
  questionTags: [],
  hasMore: false,
  activeQandOIds: { q: [], o: [] },
  isOutlineRequired: false,
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    /*
     Survey All Cases
    */
    case CREATE_NEW_SURVEY:
      return surveyReducerUtils.createSurvey(state, action);
    case EDIT_SURVEY:
      return surveyReducerUtils.editSurvey(state, action);
    case CLEAR_SURVEY:
      return surveyReducerUtils.clearSurvey(state, action);
    case TOGGLE_SURVEY:
      return surveyReducerUtils.toggleSurvey(state, action);
    case IS_SAVING:
      return { ...state, isSaving: action.payload };
    case SAVE_SURVEY:
    case EDIT_AND_SAVE:
      return surveyReducerUtils.editAndSaveSurvey(state, action);
    case FETCH_SURVEY_LIST:
      return surveyReducerUtils.fetchSurveyList(state, action);
    case FETCH_SURVEY_LIST_BY_SEARCH:
      return {
        ...state,
        survey_list: action.payload,
        isSaving: false,
      };
    case CLEAR_SURVEY_LIST:
      return {
        ...state,
        survey_list: [],
      };
    case FETCH_SURVEY_BY_ID:
      return surveyReducerUtils.fetchSurveyById(state, action);
    case FETCH_ACTIVE_QUESTION_AND_OPTION_IDS:
      return surveyReducerUtils.fetchActiveQuestionandOptionIds(state, action);
    case ADD_CRITERIA:
      return surveyReducerUtils.addCriteria(state, action);
    case CLONE_SURVEY:
      return surveyReducerUtils.fetchSurveyById(state, action);
    case DOWNLOAD_RESPONSE:
      return { ...state, isSaving: false };
    /*
     Pages All Cases
    */
    case CREATE_PAGE:
      return pageReducerUtils.createPage(state, action);
    case EDIT_PAGE:
      return pageReducerUtils.editPage(state, action);
    case SAVE_PAGE:
      return pageReducerUtils.savePage(state, action);
    case CHANGE_SELECTED_PAGE:
      return pageReducerUtils.changeSelectedPage(state, action);
    case CLONE_PAGE:
      return pageReducerUtils.clonePage(state, action);
    case DELETE_PAGE:
      return pageReducerUtils.deletePage(state, action);
    case SORT_PAGE:
      return pageReducerUtils.sortPage(state, action);

    /*
     Questions All Cases
    */
    case ADD_QUESTION:
      return questionReducerUtils.addQuestion(state, action);
    case EDIT_PROPERTIES:
      return questionReducerUtils.editProperties(state, action);
    case EDIT_QUES_TEXT:
      return questionReducerUtils.editQuesText(state, action);
    case CHANGE_FILE_TYPE:
      return questionReducerUtils.changeFileType(state, action);
    case ADD_OPTION:
      return questionReducerUtils.addOption(state, action);
    case ADD_CHOICE:
      return questionReducerUtils.addChoice(state, action);
    case REMOVE_OPTION:
      return questionReducerUtils.removeOption(state, action);
    case EDIT_OPTION_TEXT:
      return questionReducerUtils.editOptionText(state, action);
    case MARK_QUES_DONE:
      return questionReducerUtils.markQuesDone(state, action);
    case DELETE_QUESTION:
      return questionReducerUtils.deleteQues(state, action);
    case EDIT_QUESTION:
      return questionReducerUtils.editQues(state, action);
    case SAVE_DROPDOWN_CHOICES:
      return questionReducerUtils.saveDropdownChoices(state, action);
    case ADD_IMAGE_TO_OPTIONS:
      return questionReducerUtils.addImageToOptions(state, action);
    case ADD_IMAGE_TO_MATRIX_ROW:
      return questionReducerUtils.addImageToMatrixRow(state, action);
    case EDIT_OPTION_PROPERTIES:
      return questionReducerUtils.editOptionProperties(state, action);
    case DYNAMICDROPDOWN_FROM_FILE:
      return questionReducerUtils.dynamicDropdownFromFile(state, action);
    case OBJECTIVEQUESTION_FROM_FILE:
      return questionReducerUtils.objectiveQuestionFromFile(state, action);
    case CLONE_QUESTION:
      return questionReducerUtils.cloneQuestion(state, action);
    case CLONE_MULTIPLE_QUESTIONS:
      return questionReducerUtils.multipleQuestionsOperation(state, action);
    case HIDEUNHIDE_QUESTION:
      return questionReducerUtils.hideUnhideQues(state, action);
    case REORDER_QUESTIONS:
      return questionReducerUtils.reorderQuestions(state, action);
    case DELETE_MULTIPLE_QUESTIONS:
      return questionReducerUtils.multipleQuestionsOperation(state, action);
    case HIDE_UNHIDE_MULTIPLE_QUESTIONS:
      return questionReducerUtils.multipleQuestionsOperation(state, action);
    case HIDE_MATRIX_ROW:
      return questionReducerUtils.hideMatrixRow(state, action);
    case HIDE_MATRIX_COLUMN:
      return questionReducerUtils.hideMatrixColumn(state, action);
    /*
     Logics All Cases
    */
    case ADD_LOGIC:
      return logicReducerUtils.addLogic(state, action);
    case REMOVE_LOGIC:
      return logicReducerUtils.removeLogic(state, action);
    case MAKE_CRITERIA_CORRUPT:
      return logicReducerUtils.makeCriteriaCorrupt(state, action);

    // question tags
    case FETCH_QUESTION_TAGS:
      return { ...state, questionTags: action.payload };
    case SHOW_OUTLINE:
      return { ...state, isOutlineRequired: action.payload };
    case FETCH_STATE_PARTIES_LIST:
      return { ...state, stateParties: action.payload };
    default:
      return state;
  }
};

export default surveyReducer;
