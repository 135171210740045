import { SHOW_MODAL, HIDE_MODAL } from "Redux/Actions/types";

const initialState = {
  show: false,
  title: null,
  content_key: null,
  other_props: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        show: true,
        title: action.payload.title,
        content_key: action.payload.content_key,
        other_props: action.payload.other_props,
      };
    case HIDE_MODAL:
      return {
        show: false,
        title: null,
        content_key: null,
        other_props: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
