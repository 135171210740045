import { setStorage, clearItem } from "services/storage";

function createSurvey(state, action) {
  const newSurvey = {
    ...action.payload,
    selectedEditQues: null,
  };
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: {
      ...newSurvey,
    },
    isSaving: false,
  };
}

function editSurvey(state, action) {
  const { key, value } = action.payload;
  const updatedSurvey = { ...state.currentSurvey, [key]: value };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: { ...updatedSurvey },
  };
}

function editAndSaveSurvey(state, action) {
  const oldPages = state.currentSurvey.pages;
  const updatedSurvey = { ...action.payload.survey, pages: oldPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function clearSurvey(state, action) {
  clearItem("current_survey");
  clearItem("selectedIds");
  return {
    ...state,
    currentSurvey: {
      title: "",
      desc: "",
      pages: [],
      selectedPage: -1,
      selectedQues: -1,
      selectedEditQues: null,
      id: null,
    },
    selectedIds: { selectedPage: null, selectedQues: null },
  };
}

function fetchSurveyById(state, action) {
  let currSelectedPage = parseInt(action.payload.selectedPage);
  let selectedIds = {};
  if (action.payload.pages.length > 0 && currSelectedPage === -1) {
    currSelectedPage = 0;
  }
  if (action.payload.pages.length > 0) {
    selectedIds = {
      selectedPage: action.payload.pages[0]._id,
      selectedQues: null,
    };
  }
  const newSurvey = {
    ...action.payload,
    selectedPage: currSelectedPage,
    selectedQues: parseInt(action.payload.selectedQues),
    selectedEditQues: null,
  };
  setStorage("current_survey", JSON.stringify(newSurvey));
  setStorage("selectedIds", JSON.stringify(selectedIds));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
    selectedIds,
  };
}

function fetchActiveQuestionandOptionIds(state, action) {
  return {
    ...state,
    isSaving: false,
    activeQandOIds: action.payload,
  };
}

function cloneSurvey(state, action) {
  const surveyToClone = action.payload;
  setStorage("current_survey", JSON.stringify(surveyToClone));
  return {
    ...state,
    currentSurvey: surveyToClone,
    isSaving: false,
  };
}

function toggleSurvey(state, action) {
  const updatesSurveyList = state.survey_list.map((survey) => {
    if (survey._id === action.payload.id) {
      survey.is_active = action.payload.status;
    }
    return survey;
  });
  return {
    ...state,
    survey_list: updatesSurveyList,
    isSaving: false,
  };
}

function addCriteria(state, action) {
  const { criteria_id } = action.payload;
  const prevCriteria = state.currentSurvey.display_criterias.findIndex(
    (cr) => cr.criteria_id === criteria_id
  );
  if (prevCriteria !== -1) {
    state.currentSurvey.display_criterias.splice(prevCriteria, 1);
  }
  const updatedSurvey = {
    ...state.currentSurvey,
    display_criterias: [
      ...state.currentSurvey.display_criterias,
      action.payload,
    ],
  };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function fetchSurveyList(state, action) {
  let updatedSurveyList = [];
  if (action.payload.offSetValue === 0) {
    updatedSurveyList = [...new Set([...action.payload.data.msg])];
  } else {
    updatedSurveyList = [
      ...new Set([...state.survey_list, ...action.payload.data.msg]),
    ];
  }
  return {
    ...state,
    survey_list: updatedSurveyList,
    isSaving: false,
    hasMore: action.payload.data.msg.length > 0 ? true : false,
  };
}

const surveyReducerUtils = {
  createSurvey,
  editSurvey,
  clearSurvey,
  toggleSurvey,
  fetchSurveyById,
  addCriteria,
  cloneSurvey,
  editAndSaveSurvey,
  fetchSurveyList,
  fetchActiveQuestionandOptionIds,
};

export default surveyReducerUtils;
