import { combineReducers } from "redux";
import modalReducer from "./UI/modalReducer";
import alertReducer from "./UI/alertReducer";
import surveyReducer from "./Survey/surveyReducer";
import authReducer from "./Auth/authReducer";
import faReducer from "./FA/faReducer";
import surveyReportsReducer from "./SurveyReports/SurveyReportsReducer";
import reportReducer from "./Reports/reportReducer";
import individualRespReducer from "./IndividualResponse/IndividualResReducer";
const UIReducer = combineReducers({
  modal: modalReducer,
  alert: alertReducer,
});

const rootReducer = combineReducers({
  UI: UIReducer,
  auth: authReducer,
  survey: surveyReducer,
  fa: faReducer,
  surveyReports: surveyReportsReducer,
  reports: reportReducer,
  individualResponse: individualRespReducer,
});

export default rootReducer;
