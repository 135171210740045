import { setStorage, clearItem } from "services/storage";
//utils
function addQuestion(state, action) {
  const newSurvey = action.payload.survey;
  const selectedIds = {
    selectedPage: state.selectedIds.selectedPage,
    selectedQues: action.payload.created_question._id,
  };
  setStorage("selectedIds", JSON.stringify(selectedIds));
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: newSurvey,
    selectedIds,
    isSaving: false,
  };
}

function editProperties(state, action) {
  const { key, value, pId, qId, edit_key } = action.payload;
  const prevSurvey = state.currentSurvey;
  const indexOfCurrentPage = prevSurvey.pages.findIndex(
    (page) => page._id === pId
  );
  const allPages = prevSurvey.pages;

  const currentPage = prevSurvey.pages.at(indexOfCurrentPage);
  const updatedQuestions = currentPage.questions.map((question) => {
    if (question._id === qId) {
      return {
        ...question,
        [edit_key]: { ...question[edit_key], [key]: value },
      };
    }
    return question;
  });
  allPages[indexOfCurrentPage] = {
    ...currentPage,
    questions: updatedQuestions,
  };
  const updatedSurvey = { ...prevSurvey, pages: allPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function editQuesText(state, action) {
  const { value, pId, qId, key } = action.payload;
  const editedKey = key ? key : "text";
  const prevSurvey = state.currentSurvey;

  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            [editedKey]: value,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function changeFileType(state, action) {
  const { value, pId, qId } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            supported_file_types: value,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function addChoice(state, action) {
  const { pId, qId, options } = action.payload;
  const prevSurvey = state.currentSurvey;

  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            options,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function addOption(state, action) {
  const { pId, qId, score, key } = action.payload;
  const prevSurvey = state.currentSurvey;
  let optionToAdd = { text: "" };
  switch (key) {
    case "columns":
      optionToAdd = { text: "", dropdown_options: ["", "", ""] };
      break;
    case "primary_dropdown":
      optionToAdd = { text: "", dropdown_options: ["", "", ""] };
      break;

    default:
      optionToAdd = { text: "" };
  }

  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          if (question.type === "dynamic-dropdown-2") {
            optionToAdd = { text: "", score: score, sub_list: [] };
          }
          return {
            ...question,
            [key]: [...question[key], optionToAdd],
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function removeOption(state, action) {
  const { pId, qId, oIndx, key } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          // question[key].splice(oIndx, 1);
          return {
            ...question,
            [key]: question[key].filter((v, i) => i !== oIndx),
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function editOptionText(state, action) {
  const { value, pId, qId, oIndx, key } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question[key].map((option, index) => {
            if (index === oIndx) {
              return {
                ...option,
                text: value,
              };
            }
            return option;
          });
          return {
            ...question,
            [key]: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: { ...updatedSurvey },
  };
}

function markQuesDone(state, action) {
  const newSurvey = action.payload.survey;
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
  };
}

function deleteQues(state, action) {
  const newSurvey = action.payload.survey;
  let newSelectedQues = null;
  const currentPage = newSurvey.pages.find(
    (page) => page._id === state.selectedIds.selectedPage
  );
  if (currentPage.questions.length > 0) {
    newSelectedQues =
      currentPage.questions[currentPage.questions.length - 1]._id;
  }
  const selectedIds = {
    selectedPage: state.selectedIds.selectedPage,
    selectedQues: newSelectedQues,
  };
  setStorage("current_survey", JSON.stringify(newSurvey));
  setStorage("selectedIds", JSON.stringify(selectedIds));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
    selectedIds,
  };
}

function editQues(state, action) {
  const { pId, qId } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            is_complete: false,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const selectedIds = {
    selectedPage: state.selectedIds.selectedPage,
    selectedQues: qId,
  };

  const updatedSurvey = {
    ...prevSurvey,
    pages: updatedPages,
  };

  setStorage("current_survey", JSON.stringify(updatedSurvey));
  setStorage("selectedIds", JSON.stringify(selectedIds));

  return {
    ...state,
    currentSurvey: updatedSurvey,
    selectedIds,
  };
}

function saveDropdownChoices(state, action) {
  const { choices, pId, qId, clIndx, key } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          let updatedColumns = [];
          if (
            question.type === "dynamic-dropdown" ||
            question.type === "matrix-dropdown"
          ) {
            updatedColumns = question[key].map((column, index) => {
              if (index === clIndx) {
                return {
                  ...column,
                  dropdown_options: choices,
                };
              }
              return column;
            });
          } else if (question.type === "dynamic-dropdown-2") {
            updatedColumns = choices;
          }

          return {
            ...question,
            [key]: updatedColumns,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });
  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function addImageToOptions(state, action) {
  const { image_url, pId, qId, opIndx } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question.options.map((option, index) => {
            if (index === opIndx) {
              return {
                ...option,
                image_url,
              };
            }
            return option;
          });
          return {
            ...question,
            options: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function addImageToMatrixRow(state, action) {
  const { imageUrl, pId, qId, opIndx } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question.rows.map((row, index) => {
            if (index === opIndx) {
              return {
                ...row,
                image_url: imageUrl,
              };
            }
            return row;
          });
          return {
            ...question,
            rows: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function editOptionProperties(state, action) {
  const { pId, qId, option } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question.options.map((opt) => {
            if (opt._id === option._id) {
              return {
                ...option,
              };
            }
            return opt;
          });
          return {
            ...question,
            options: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function makeOptionStatement(state, action) {
  const { is_statement, pId, qId, opIndx } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question.options.map((option, index) => {
            if (index === opIndx) {
              return {
                ...option,
                is_statement,
              };
            }
            return option;
          });
          return {
            ...question,
            options: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function hideOption(state, action) {
  const { is_hidden, pId, qId, opIndx } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedOptions = question.options.map((option, index) => {
            if (index === opIndx) {
              return {
                ...option,
                is_hidden,
              };
            }
            return option;
          });
          return {
            ...question,
            options: updatedOptions,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function hideMatrixRow(state, action) {
  const { value, key, sPage, qId, opIndex } = action.payload;
  const prevSurvey = state.currentSurvey;

  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === sPage) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedRows = question.rows.map((row, index) => {
            if (index === opIndex) {
              return {
                ...row,
                [key]: value,
              };
            }
            return row;
          });
          return {
            ...question,
            rows: updatedRows,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };

  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function hideMatrixColumn(state, action) {
  const { value, key, sPage, qId, opIndex } = action.payload;
  const prevSurvey = state.currentSurvey;

  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === sPage) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          const updatedColumns = question.columns.map((column, index) => {
            if (index === opIndex) {
              return {
                ...column,
                [key]: value,
              };
            }
            return column;
          });
          return {
            ...question,
            columns: updatedColumns,
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };

  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function dynamicDropdownFromFile(state, action) {
  const { data, pId, qId, key } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            [key]: [...data],
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function objectiveQuestionFromFile(state, action) {
  const { data, pId, qId } = action.payload;
  const prevSurvey = state.currentSurvey;
  const updatedPages = prevSurvey.pages.map((page) => {
    if (page._id === pId) {
      const updatedQues = page.questions.map((question) => {
        if (question._id === qId) {
          return {
            ...question,
            options: [...data],
          };
        }
        return question;
      });
      return {
        ...page,
        questions: updatedQues,
      };
    }
    return page;
  });

  const updatedSurvey = { ...prevSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
  };
}

function cloneQuestion(state, action) {
  const newSurvey = action.payload.survey;
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
  };
}

function multipleQuestionsOperation(state, action) {
  const updatedPage = action.payload.page;
  const indexOfUpdatedPage = state.currentSurvey.pages.findIndex(
    (page) => page._id === updatedPage._id
  );
  const updatedPages = state.currentSurvey.pages.slice();
  if (indexOfUpdatedPage !== -1) updatedPages[indexOfUpdatedPage] = updatedPage;
  const updatedSurvey = { ...state.currentSurvey, pages: updatedPages };
  setStorage("current_survey", JSON.stringify(updatedSurvey));
  return {
    ...state,
    currentSurvey: updatedSurvey,
    isSaving: false,
  };
}

function hideUnhideQues(state, action) {
  const newSurvey = action.payload.survey;
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
  };
}

function reorderQuestions(state, action) {
  const newSurvey = action.payload.survey;
  setStorage("current_survey", JSON.stringify(newSurvey));
  return {
    ...state,
    currentSurvey: newSurvey,
    isSaving: false,
  };
}

const questionReducerUtils = {
  addQuestion,
  editProperties,
  editQuesText,
  addOption,
  removeOption,
  editOptionText,
  markQuesDone,
  deleteQues,
  editQues,
  saveDropdownChoices,
  addImageToOptions,
  changeFileType,
  dynamicDropdownFromFile,
  objectiveQuestionFromFile,
  cloneQuestion,
  hideUnhideQues,
  makeOptionStatement,
  reorderQuestions,
  hideOption,
  addChoice,
  editOptionProperties,
  addImageToMatrixRow,
  multipleQuestionsOperation,
  hideMatrixRow,
  hideMatrixColumn,
};
export default questionReducerUtils;
