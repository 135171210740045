import { SHOW_ALERT, HIDE_ALERT } from "Redux/Actions/types";

const initialState = {
  show: false,
  msg: null,
  severity: null,
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        show: true,
        msg: action.payload.msg,
        severity: action.payload.severity,
      };
    case HIDE_ALERT:
      return {
        show: false,
        msg: null,
        severity: null,
      };
    default:
      return state;
  }
};

export default alertReducer;
