import { FETCH_SURVEY_SUMMARY, IS_SUMMARY_FETCHING } from "Redux/Actions/types";

const initialState = {
  summary: {},
  analysis: {},
  isFetching: false,
};

const surveyReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_SUMMARY_FETCHING:
      return { ...state, isFetching: action.isFetching };
    case FETCH_SURVEY_SUMMARY:
      return { ...state, summary: action.summary };
    default:
      return state;
  }
};

export default surveyReportsReducer;
